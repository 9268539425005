import React from 'react';
import { CreditCardIcon, ShieldCheckIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';

const PaymentAlert = ({ order, getPaymentMethod, formatDate }) => {
  const paymentMethod = getPaymentMethod();

  const getTransactionId = () => {
    if (order.payment_method === 'tilopay') {
      const authMeta = order.meta_data.find(meta => meta.key === 'tilopay_auth_code');
      if (authMeta) {
        return authMeta.value;
      }
    }
    return order.transaction_id || 'Aún no registrado';
  };

  const transactionId = getTransactionId();
  const datePaid = order.date_paid ? formatDate(order.date_paid) : 'Aún no registrado';

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Métodos de Pago
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Detalles de pago del Pedido.
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
            <div className="sm:col-span-1">
              <div className="flex items-center">
                <CreditCardIcon className="w-6 h-6 mr-2 text-indigo-600" />
                <dt className="text-sm font-medium text-indigo-600">Método de Pago</dt>
              </div>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                <svg className="w-1.5 h-1.5 mr-2 text-black" fill="currentColor" viewBox="0 0 20 20">
                  <circle cx="10" cy="10" r="10" />
                </svg>
                {paymentMethod}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <div className="flex items-center">
                <ShieldCheckIcon className="w-6 h-6 mr-2 text-indigo-600" />
                <dt className="text-sm font-medium text-indigo-600">ID de Transacción</dt>
              </div>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                <svg className="w-1.5 h-1.5 mr-2 text-black" fill="currentColor" viewBox="0 0 20 20">
                  <circle cx="10" cy="10" r="10" />
                </svg>
                {transactionId}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <div className="flex items-center">
                <CalendarDaysIcon className="w-6 h-6 mr-2 text-indigo-600" />
                <dt className="text-sm font-medium text-indigo-600">Fecha de Pago</dt>
              </div>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                <svg className="w-1.5 h-1.5 mr-2 text-black" fill="currentColor" viewBox="0 0 20 20">
                  <circle cx="10" cy="10" r="10" />
                </svg>
                {datePaid}
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default PaymentAlert;
