// src/components/orders/statusHelpers.js

export const getStatusBadgeColor = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-50 text-yellow-800';
      case 'on-hold':
        return 'bg-yellow-50 text-yellow-800';
      case 'processing':
        return 'bg-blue-50 text-blue-700';
      case 'completed':
        return 'bg-green-50 text-green-700';
      case 'cancelled':
        return 'bg-red-50 text-red-700';
      case 'refunded':
        return 'bg-purple-50 text-purple-700';
      case 'failed':
        return 'bg-gray-50 text-gray-700';
      default:
        return 'bg-gray-50 text-gray-600';
    }
  };
  
  export const statusNames = {
    pending: 'Pago Pendiente',
    processing: 'Nueva Reserva',
    completed: 'Viaje Finalizado',
    cancelled: 'Viaje Cancelado',
    refunded: 'Reembolsado',
    failed: 'Pago Fallido',
    'on-hold': 'En Rutero',
  };
  