import React, { useState, useRef, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Agregar useNavigate
import { 
  IoTicketOutline, 
  IoNotifications, 
  IoSwapHorizontalOutline, 
  IoMapOutline 
} from 'react-icons/io5';
import { UserContext } from '../contexts/UserContext';
import Logo from '../assets/images/kuv-digital-logo.svg';

const SidebarWithHeader = ({ children }) => {
  const { user, isLoggedIn, logout } = useContext(UserContext); // Usar logout del contexto
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate(); // Para redirigir después del logout

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleLogout = () => {
    logout(); // Llamar a la función de logout del contexto
    navigate('/'); // Redirigir al usuario a la página de inicio de sesión
  };

  const getInitials = (name) => {
    if (!name) return '??';
    const [firstName = '', lastName = ''] = name.split(' ');
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  // Navegación condicional basada en roles
  const navigation = [
    ...(user?.roles?.includes('shop_manager') || user?.roles?.includes('administrator')
      ? [{ name: 'Pedidos', href: '/orders', icon: <IoTicketOutline className="w-5 h-5" /> }]
      : []),
    ...(user?.roles?.includes('driver')
      ? [{ name: 'Mi Ruta', href: '/my-route', icon: <IoMapOutline className="w-5 h-5" /> }]
      : []),
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="h-screen flex">
      {/* Sidebar */}
      <div className={`bg-[#020617] text-white ${isSidebarOpen ? 'w-64' : 'w-20'} p-6 flex flex-col h-full fixed transition-all duration-300`}>
        <div className="mb-6 flex justify-center">
          <img src={Logo} alt="Logo de la Empresa" className={`transition-all ${isSidebarOpen ? 'h-16' : 'h-10'}`} />
        </div>

        <nav className="flex flex-col flex-1 space-y-2">
          {isLoggedIn && navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={`py-2 px-4 hover:bg-indigo-600 hover:text-white rounded flex items-center ${isSidebarOpen ? 'justify-start' : 'justify-center'}`}
            >
              <span>{item.icon}</span>
              {isSidebarOpen && <span className="ml-4">{item.name}</span>}
            </Link>
          ))}
        </nav>

        <div className="mt-auto">
          <button
            onClick={toggleSidebar}
            className="py-2 px-4 hover:bg-indigo-600 hover:text-white w-full flex items-center justify-center"
          >
            <IoSwapHorizontalOutline className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Main content */}
      <div className={`flex-1 flex flex-col ${isSidebarOpen ? 'ml-64' : 'ml-20'} transition-all duration-300`}>
        {/* Header */}
        <header className="bg-white shadow-sm p-4 flex justify-end items-center w-full">
          <div className="flex items-center space-x-4 relative">
            <IoNotifications className="w-6 h-6 text-gray-600" />

            <div
              className="flex items-center justify-center w-10 h-10 rounded-full bg-[#020617] text-[#cbd5e1] cursor-pointer"
              onClick={toggleDropdown}
              ref={dropdownRef}
            >
              <span className="text-xl font-semibold">{getInitials(user?.first_name + ' ' + user?.last_name)}</span>
            </div>

            {isDropdownOpen && (
              <div className="absolute top-1 right-6 mt-10 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10" ref={dropdownRef}>
                <Link to="/profile" className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">
                  Your Profile
                </Link>
                <Link to="/settings" className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">
                  Settings
                </Link>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white"
                >
                  Sign out
                </button>
              </div>
            )}
          </div>
        </header>

        {/* Dynamic content */}
        <main className="flex-1 overflow-y-auto p-6 bg-gray-100">
          {children}
        </main>
      </div>
    </div>
  );
};

export default SidebarWithHeader;
