import React, { useState, useEffect } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import axios from 'axios';
import Notification from './Notification';
import { FiLoader } from 'react-icons/fi'; // Importamos un spinner de react-icons

const API = axios.create({ baseURL: 'https://app.transporzuma.com/admin/api/' });

const UpdateOrderModal = ({ isOpen, onClose, orderNumber, onUpdateStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Estado para manejar el loading
  const [showNotification, setShowNotification] = useState(false); // Estado para mostrar notificación

  const orderStatuses = [
    'Pago Pendiente',
    'Nueva Reserva',
    'Viaje Finalizado',
    'Viaje Cancelado',
    'Reembolsado',
    'Pago Fallido',
    'En Rutero'
  ];

  const statusMapping = {
    'Pago Pendiente': 'pending',
    'Nueva Reserva': 'processing',
    'Viaje Finalizado': 'completed',
    'Viaje Cancelado': 'cancelled',
    'Reembolsado': 'refunded',
    'Pago Fallido': 'failed',
    'En Rutero': 'on-hold'
  };

  const handleSubmit = async () => {
    if (!selectedStatus) {
      console.error('Debes seleccionar un estado antes de actualizar.');
      return;
    }

    setIsLoading(true); // Activamos el estado de loading

    try {
      const status = statusMapping[selectedStatus];
      if (!status) throw new Error('Estado no válido');

      await API.put(`/orders/${orderNumber}`, { status });
      onUpdateStatus(status); // Actualiza el estado en el componente principal
      setShowNotification(true); // Muestra la notificación
      onClose(); // Cierra el modal después de actualizar
    } catch (error) {
      console.error('Error al actualizar el estado:', error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false); // Desactivamos el estado de loading
    }
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedStatus(''); // Reinicia el estado seleccionado al abrir el modal
      setShowNotification(false); // Oculta la notificación al abrir el modal
    }
  }, [isOpen]);

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} className="relative z-50">
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50" />
        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="px-4 py-5 sm:p-6">
                <DialogTitle as="h3" className="text-lg font-bold text-gray-800">
                  Actualizar Estado del Pedido #{orderNumber}
                </DialogTitle>

                <div className="mt-4">
                  <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                    Selecciona el nuevo estado
                  </label>
                  <select
                    id="status"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    className="mt-2 block w-full border border-gray-300 rounded-md p-2"
                  >
                    <option value="">Selecciona un estado</option>
                    {orderStatuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Botones de acción */}
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  onClick={handleSubmit}
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 sm:ml-3 sm:w-auto"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="flex items-center">
                      <FiLoader className="animate-spin mr-2" /> Actualizando...
                    </span>
                  ) : (
                    'Actualizar'
                  )}
                </button>
                <button
                  onClick={onClose}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancelar
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      {/* Renderiza la notificación de éxito si showNotification es true */}
      {showNotification && (
        <Notification
          message="Estado del pedido actualizado exitosamente."
          onClose={() => setShowNotification(false)}
          type="success"
        />
      )}
    </>
  );
};

export default UpdateOrderModal;
