import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { FiUser, FiUserCheck, FiServer } from 'react-icons/fi';
import Notification from './Notification';

const API = axios.create({ baseURL: 'https://app.transporzuma.com/admin/api/' });

const OrderNotesDrawer = ({ isOpen, onClose, onSubmit, orderId }) => {
  const [note, setNote] = useState('');
  const [visibility, setVisibility] = useState('private');
  const [notification, setNotification] = useState(null);
  const [orderNotes, setOrderNotes] = useState([]);

  // Fetch order notes when drawer is opened
  useEffect(() => {
    if (isOpen && orderId) {
      const fetchOrderNotes = async () => {
        try {
          const response = await API.get(`/orders/${orderId}/notes`);
          setOrderNotes(response.data);
        } catch (error) {
          console.error('Error fetching order notes:', error);
          setNotification({ message: 'Error al obtener las notas del pedido.', type: 'error' });
        }
      };
      fetchOrderNotes();
    }
  }, [isOpen, orderId]);

  const handleSaveNote = async () => {
    if (!orderId || !note.trim()) {
      setNotification({ message: 'El ID del pedido o la nota no son válidos.', type: 'error' });
      return;
    }

    // Confirm before saving the note
    const isConfirmed = window.confirm(
      '¿Está seguro que desea adjuntar esta nota? Las notas no se pueden eliminar.'
    );
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await API.post(`/orders/${orderId}/notes`, {
        note,
        customer_note: visibility === 'client',
      });
      setOrderNotes([...orderNotes, response.data]);
      setNotification({ message: 'Nota agregada con éxito.', type: 'success' });
      setNote('');
      setVisibility('private');
      if (onSubmit) onSubmit(response.data);
    } catch (error) {
      setNotification({ message: `Error al agregar la nota: ${error.response?.data?.message || error.message}`, type: 'error' });
      console.error('Error al agregar la nota:', error);
    }
  };

  const determineAuthorType = (note) => {
    if (note.author === 'system') {
      return 'Nota de Sistema';
    } else if (note.customer_note) {
      return 'Nota del Cliente';
    } else {
      return 'Nota de Administrador';
    }
  };
  

  const getAuthorIcon = (authorType) => {
    switch (authorType) {
      case 'Nota de Sistema':
        return <FiServer className="h-8 w-8 text-gray-400" />;
      case 'Nota del Cliente':
        return <FiUserCheck className="h-8 w-8 text-gray-400" />;
      case 'Nota de Administrador':
      default:
        return <FiUser className="h-8 w-8 text-gray-400" />;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-50 inset-0 flex items-end justify-end bg-gray-800 bg-opacity-50">
      <div className="bg-white w-full max-w-lg h-full shadow-lg flex flex-col relative z-50 overflow-hidden">
        {/* Header */}
        <div className="bg-[#020617] h-[64px] flex items-center justify-between p-6 text-white">
          <h3 className="text-lg font-bold">Actividad del Pedido {orderId}</h3>
          <button onClick={onClose} className="text-gray-300 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
  
        {/* Activity Feed with Scroll */}
        <div className="flex-grow overflow-y-auto mb-4 space-y-4 border-b pb-4 max-h-[500px] p-4">
          {orderNotes.length > 0 ? (
            orderNotes.map((note, index) => {
              const authorType = determineAuthorType(note);
              return (
                <div key={index} className="flex items-start space-x-3">
                  <div className="flex-shrink-0">
                    {getAuthorIcon(authorType)}
                  </div>
                  <div>
                    <div className="flex items-center space-x-2">
                      <p className="text-sm font-bold text-gray-800">{authorType}</p>
                      <p className="text-xs text-gray-500">
                        {new Date(note.date_created).toLocaleDateString('es-ES', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        })}
                      </p>
                    </div>
                    <p className="text-sm text-gray-800 mt-1">{note.note}</p>
                    <p className="text-xs text-gray-500 mt-1">
                      Visibilidad: {note.customer_note ? 'Cliente' : 'Privada'}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-sm text-gray-500">No hay notas para este pedido.</p>
          )}
        </div>

        <div className="p-4 bg-white">
          <h4 className="text-md font-semibold text-gray-800 mb-2">Agregar nueva nota</h4>
          <textarea
            className="w-full border border-gray-300 rounded-md p-2 text-sm focus:ring-indigo-500 focus:border-indigo-500 mb-3"
            rows="4"
            placeholder="Escribe la nota aquí..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <select
            className="w-full border border-gray-300 rounded-md p-2 text-sm focus:ring-indigo-500 focus:border-indigo-500 mb-3"
            value={visibility}
            onChange={(e) => setVisibility(e.target.value)}
          >
            <option value="private">Privada</option>
            <option value="client">Notificar al Cliente</option>
          </select>
          {notification && (
            <div
              className={`mb-4 p-2 text-sm rounded-md ${
                notification.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
              }`}
            >
              {notification.message}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="flex justify-between p-4 bg-[#f1f5f9]">
          <button
            onClick={onClose}
            className="bg-white text-black px-4 py-2 rounded-md hover:bg-gray-200 transition"
          >
            Cerrar
          </button>
          <button
            onClick={handleSaveNote}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
          >
            Guardar Nota
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderNotesDrawer;
