import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext'; // Importar UserContext
import { IoLockClosedOutline } from "react-icons/io5";
import kuvLogo from '../assets/images/logo-kuv-panel.svg';

const Login = () => {
  const { setUser, setToken, setIsLoggedIn } = useContext(UserContext); // Extraer funciones del contexto
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axios.post(
        'https://app.transporzuma.com/admin/api/auth/login',
        { email, password },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const { token, first_name, last_name, email: userEmail, roles } = response.data;

      // Validación adicional de roles
      if (!Array.isArray(roles) || roles.length === 0) {
        setError('No se detectaron roles válidos para este usuario.');
        setLoading(false);
        return;
      }

      // Almacenar el token y los datos del usuario usando el contexto
      setToken(token);
      setUser({ first_name, last_name, email: userEmail, roles });
      setIsLoggedIn(true);

      // Almacenar el tiempo de última actividad
      localStorage.setItem('lastActivity', Date.now());

      // Redirección basada en roles
      if (roles.includes('driver')) {
        navigate('/my-route');
      } else if (roles.includes('shop_manager') || roles.includes('administrator')) {
        navigate('/orders');
      } else {
        setError('No tienes acceso a esta plataforma.');
        setLoading(false);
        return;
      }

      console.log(`Usuario autenticado: ${userEmail}, Roles: ${roles.join(', ')}`);

    } catch (error) {
      console.error('Error en el login:', error.response?.data || error.message);
      const errorMessage = error.response?.data?.message || 'Credenciales incorrectas. Verifica tus datos.';
      setError(errorMessage); // Mostrar mensaje de error específico
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0f172a]">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-3xl flex flex-col md:flex-row items-center">
        <div className="flex justify-center w-full md:w-1/3 mb-6 md:mb-0">
          <img src={kuvLogo} alt="KUV Digital Logo" className="h-16 mx-auto" />
        </div>
        <div className="w-full md:w-2/3">
          <h1 className="text-xl font-bold text-center md:text-left mb-4">PANEL DE ADMINISTRACIÓN DE KUV DIGITAL</h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                CORREO ELECTRÓNICO DEL ADMINISTRADOR
              </label>
              <input
                type="email"
                id="email"
                placeholder="Su correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                required
                aria-label="Correo electrónico"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                ADMIN CONTRASEÑA
              </label>
              <input
                type="password"
                id="password"
                placeholder="Su contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                required
                aria-label="Contraseña"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white py-2 rounded-lg hover:bg-indigo-700 flex items-center justify-center space-x-2"
              disabled={loading}
            >
              <IoLockClosedOutline className="w-5 h-5" />
              <span>{loading ? 'Cargando...' : 'Iniciar sesión'}</span>
            </button>
            {error && <p className="text-red-500 text-center mt-4">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
