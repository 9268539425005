import React, { useState } from 'react';
import { MdContentCopy } from "react-icons/md";

const TravelDetails = ({ order, getPickupDetails, getDropoffDetails }) => {
  const [copiedTooltip, setCopiedTooltip] = useState(null);

  const handleCopy = (textToCopy, field) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopiedTooltip(field); // Mostrar el tooltip correspondiente
        setTimeout(() => setCopiedTooltip(null), 2000); // Ocultar el tooltip después de 2 segundos
      })
      .catch(() => alert('Error al copiar la información'));
  };

  const pickupDetails = getPickupDetails(order.meta_data) || 'N/A';
  const dropoffDetails = getDropoffDetails(order.meta_data) || 'N/A';

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Detalles del Viaje
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Detalles de Salida y Llegada del Pasajero.
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          {/* Pickup Details */}
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
            <div className="sm:col-span-1 flex items-center relative">
              <dt className="text-sm font-medium text-indigo-600">PICKUP</dt>
              <dd className="ml-4 mt-1 text-sm text-gray-900 flex items-center">
                {pickupDetails}
                <div className="relative">
                  <MdContentCopy
                    className="w-5 h-5 text-gray-500 cursor-pointer ml-2 hover:text-gray-700"
                    onClick={() => handleCopy(pickupDetails, 'pickup')}
                    title="Copiar detalles de PICKUP"
                  />
                  {copiedTooltip === 'pickup' && (
                    <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded-md py-1 px-2">
                      Copiado
                    </div>
                  )}
                </div>
              </dd>
            </div>

            {/* Dropoff Details */}
            <div className="sm:col-span-1 flex items-center relative">
              <dt className="text-sm font-medium text-indigo-600">DROPOFF</dt>
              <dd className="ml-4 mt-1 text-sm text-gray-900 flex items-center">
                {dropoffDetails}
                <div className="relative">
                  <MdContentCopy
                    className="w-5 h-5 text-gray-500 cursor-pointer ml-2 hover:text-gray-700"
                    onClick={() => handleCopy(dropoffDetails, 'dropoff')}
                    title="Copiar detalles de DROPOFF"
                  />
                  {copiedTooltip === 'dropoff' && (
                    <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded-md py-1 px-2">
                      Copiado
                    </div>
                  )}
                </div>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default TravelDetails;
