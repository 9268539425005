import React from 'react';

const AlertsOrders = ({ order }) => {
  const getAlertDetails = (order) => {
    switch (order.status) {
      case 'cancelled':
        return {
          color: 'bg-red-50 border border-red-200 text-red-800',
          icon: 'x-circle',
          title: 'Pedido Cancelado',
          message: `Este pedido fue cancelado el ${new Date(order.date_modified).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })}.`
        };
      case 'failed':
        return {
          color: 'bg-red-50 border border-red-200 text-red-800',
          icon: 'x-circle',
          title: 'Pago Fallido',
          message: `El intento de pago falló debido a falta de fondos el ${new Date(order.date_modified).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })}.`
        };
      case 'refunded':
        return {
          color: 'bg-red-50 border border-red-200 text-red-800',
          icon: 'check-circle',
          title: 'Pedido Reembolsado',
          message: `Este pedido fue reembolsado el ${new Date(order.date_modified).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })}.`
        };
      case 'completed':
        return {
          color: 'bg-green-50 border border-green-200 text-green-800',
          icon: 'check-circle',
          title: 'Viaje Completado',
          message: `El cliente ha completado su viaje el ${new Date(order.date_paid).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })}.`
        };
      case 'processing':
        return {
          color: 'bg-blue-50 border border-blue-200 text-blue-800',
          icon: 'check-circle',
          title: 'Nuevo Pedido Recibido',
          message: 'Este pedido ha sido recibido y está pendiente de confirmación para el cliente.'
        };
      case 'on-hold':
        return {
          color: 'bg-yellow-50 border border-yellow-200 text-yellow-800',
          icon: 'exclamation-triangle',
          title: 'Pedido Agendado',
          message: 'El pedido ha sido agendado y está confirmado para el cliente.'
        };
      case 'pending':
      default:
        return {
          color: 'bg-yellow-50 border border-yellow-200 text-yellow-800',
          icon: 'exclamation-triangle',
          title: 'Pago Pendiente',
          message: 'Este pedido está pendiente de pago. Contacta al cliente para continuar con la reservación.'
        };
    }
  };

  const { color, icon, title, message } = getAlertDetails(order);

  return (
    <div className={`p-4 rounded-md flex items-center ${color}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d={icon === 'check-circle' ? 'M9 12l2 2 4-4m1-5a9 9 0 11-6 18 9 9 0 016-18z' : icon === 'exclamation-triangle' ? 'M12 8v4l3 3m9-2a9 9 0 11-18 0 9 9 0 0118 0z' : 'M6 18L18 6M6 6l12 12'}
        />
      </svg>
      <div>
        <p className="font-semibold">{title}</p>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default AlertsOrders;
