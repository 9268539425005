import React, { useState } from 'react';
import { getStatusBadgeColor, statusNames } from './statusHelpers';
import { MdOutlineContactEmergency, MdOutlineContactPhone, MdContentCopy } from "react-icons/md";

const CustomerInfo = ({ order, getTravelDate }) => {
  const [copiedTooltip, setCopiedTooltip] = useState(false);

  const formatPurchaseDate = (dateString) => {
    const [datePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    return `${day}/${month}/${year}`;
  };

  const formatTravelDate = (dateString) => {
    if (!dateString) return 'N/A';
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const getTravelDateMeta = (metaData) => {
    const travelDateMeta = metaData.find(meta => meta.key === '_billing_departure_date');
    return travelDateMeta ? travelDateMeta.value : null;
  };

  const getTravelTime = (metaData) => {
    const departureTimeMeta = metaData.find(meta => meta.key === '_billing_departure_time');
    return departureTimeMeta ? departureTimeMeta.value : 'N/A';
  };

  const handleCopy = () => {
    const textToCopy = `${order.billing.first_name} ${order.billing.last_name} - ${order.billing.phone}`;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopiedTooltip(true); // Mostrar tooltip
        setTimeout(() => setCopiedTooltip(false), 2000); // Ocultar tooltip después de 2 segundos
      })
      .catch(() => alert('Error al copiar la información'));
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Información del Pasajero
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Detalles de contacto.
          </p>
        </div>
        <div className="flex items-center space-x-2">
          {/* Badge de estado del pedido */}
          <span className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${getStatusBadgeColor(order.status)}`}>
            {statusNames[order.status]}
          </span>

          {/* Badge adicional para Pagado si tiene un ID de pago */}
          {order.date_paid && (
            <span className={`inline-block px-2 py-1 text-xs font-semibold rounded-full text-green-600 bg-green-100`}>
              Pagado
            </span>
          )}
        </div>
      </div>

      <div className="border-t border-gray-200">
        <dl className="flex flex-wrap">
          <div className="w-full sm:w-1/2 px-4 py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Nombre</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 flex items-center space-x-2 relative">
              <span>
                <span className="font-bold text-indigo-600">{order.billing.first_name}</span>{' '}
                <span className="font-semibold text-black">{order.billing.last_name}</span>
              </span>
              <div className="relative">
                <MdContentCopy
                  className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700"
                  onClick={handleCopy}
                  title="Copiar nombre y teléfono"
                />
                {copiedTooltip && (
                  <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded-md py-1 px-2">
                    Copiado
                  </div>
                )}
              </div>
            </dd>
            <dd className="mt-1 text-sm text-gray-500 sm:mt-0">{order.billing.email}</dd>
          </div>
          <div className="w-full sm:w-1/2 px-4 py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Información del Pasajero</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 flex flex-col">
              <span className="flex items-center mb-1">
                <MdOutlineContactPhone className="w-6 h-6 mr-2 text-indigo-600" />
                {order.billing.phone || 'N/A'}
              </span>
              <span className="flex items-center mt-1">
                <MdOutlineContactEmergency className="w-6 h-6 mr-2 text-indigo-600" />
                {order.billing.email || 'N/A'}
              </span>
            </dd>
          </div>
        </dl>
      </div>

      <div className="border-t border-gray-200">
        <div className="flex">
          <div className="w-1/3 p-4 text-center">
            <span className="block text-sm font-medium text-gray-500">Fecha de Compra</span>
            <span className="block text-sm text-gray-900">{formatPurchaseDate(order.date_created)}</span>
          </div>
          <div className="w-1/3 p-4 text-center border-l border-r border-gray-200">
            <span className="block text-sm font-medium text-gray-500">Fecha de Viaje</span>
            <span className="block text-sm text-gray-900">{formatTravelDate(getTravelDateMeta(order.meta_data))}</span>
          </div>
          <div className="w-1/3 p-4 text-center">
            <span className="block text-sm font-medium text-gray-500">Hora de Viaje</span>
            <span className="block text-sm text-gray-900">{getTravelTime(order.meta_data)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
