import React, { useContext, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';

const MyRoute = () => {
  const { assignedOrders, user, fetchAssignedOrders } = useContext(UserContext);

  useEffect(() => {
    if (user?.id) {
      fetchAssignedOrders(user.id); // Obtener los pedidos asignados al usuario actual
    }
  }, [user, fetchAssignedOrders]);

  if (!user) {
    return <p className="text-center">Debes iniciar sesión para ver tus pedidos asignados.</p>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Mi Rutas</h1>
      {assignedOrders.length === 0 ? (
        <p className="text-center">No tienes pedidos asignados.</p>
      ) : (
        <ul>
          {assignedOrders.map((order) => (
            <li key={order.id} className="border p-4 mb-4 rounded shadow">
              <h2 className="text-xl font-semibold">Pedido #{order.id}</h2>
              <p>Estado: {order.status}</p>
              <p>Total: ${order.total}</p>
              <p>Cliente: {order.billing?.first_name} {order.billing?.last_name}</p>
              <p>Dirección: {order.shipping?.address_1}, {order.shipping?.city}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MyRoute;
