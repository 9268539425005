import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import UserProvider, { UserContext } from './contexts/UserContext';
import SidebarWithHeader from './layout/SidebarWithHeader';
import Login from './components/Login';
import Orders from './pages/Orders';
import Profile from './components/Users/MyProfile';
import Choferes from './pages/MyRoute';

const PrivateRoute = ({ children }) => {
  const { isLoggedIn } = useContext(UserContext);
  return isLoggedIn ? children : <Navigate to="/login" />;
};

const App = () => (
  <UserProvider>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <SidebarWithHeader>
                <div className="p-4">
                  <Routes>
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/my-route" element={<Choferes />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/my-profile" element={<Profile />} />
                  </Routes>
                </div>
              </SidebarWithHeader>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  </UserProvider>
);

export default App;
