import React, { useState, useEffect, useRef } from 'react';
import AlertsOrders from './AlertsOrders';
import CustomerInfo from './CustomerInfo';
import RouteExtras from './RouteExtras';
import TravelDetails from './TravelDetails';
import PaymentAlert from './PaymentAlert';
import UpdateOrderModal from './UpdateOrderModal';
import Notification from './Notification';
import OrderNotesDrawer from './OrderNotesDrawer';
import ReportPaymentModal from './ReportPaymentModal';
import axios from 'axios';

const API = axios.create({ baseURL: 'https://app.transporzuma.com/admin/api/orders' });

const DetallesOrden = ({ order, onClose, onUpdateStatus }) => {
  // States
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isOrderNotesDrawerOpen, setOrderNotesDrawerOpen] = useState(false);
  const [isReportPaymentModalOpen, setReportPaymentModalOpen] = useState(false);
  const [orderNotes, setOrderNotes] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const dropdownRef = useRef(null);

  // Fetch order notes
  useEffect(() => {
    const fetchOrderNotes = async () => {
      try {
        const response = await API.get(`/orders/${order.id}/notes`);
        setOrderNotes(response.data);
      } catch (error) {
        console.error('Error fetching order notes:', error);
      }
    };
    fetchOrderNotes();
  }, [order.id]);

  // Utility functions
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  const getTravelDate = (metaData) => {
    if (!Array.isArray(metaData)) return 'N/A';
    const travelDateMeta = metaData.find(meta => meta.key === '_billing_departure_time');
    return travelDateMeta ? travelDateMeta.value : '';
  };

  const getPickupDetails = (metaData) => {
    if (!Array.isArray(metaData)) return 'N/A';
    const pickupMeta = metaData.find(meta => meta.key === '_billing_pickup');
    return pickupMeta ? pickupMeta.value : 'N/A';
  };

  const getDropoffDetails = (metaData) => {
    if (!Array.isArray(metaData)) return 'N/A';
    const dropoffMeta = metaData.find(meta => meta.key === '_billing_dropoff');
    return dropoffMeta ? dropoffMeta.value : 'N/A';
  };

  const getPaymentMethod = () => {
    return order.payment_method_title || 'Tarjeta de Crédito / Débito';
  };

  // Modal and dropdown handlers
  const openUpdateModal = () => {
    setUpdateModalOpen(true);
    setDropdownOpen(false);
  };

  const closeUpdateModal = () => setUpdateModalOpen(false);

  const openOrderNotesDrawer = () => {
    setOrderNotesDrawerOpen(true);
    setDropdownOpen(false);
  };

  const closeOrderNotesDrawer = () => setOrderNotesDrawerOpen(false);

  const openReportPaymentModal = () => {
    setReportPaymentModalOpen(true);
    setDropdownOpen(false);
  };

  const closeReportPaymentModal = () => setReportPaymentModalOpen(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleUpdateStatus = (status) => {
    setShowNotification(true);
    setNotificationMessage('Pedido actualizado exitosamente');
    setUpdateModalOpen(false); // Close update modal
  };

  const handleSaveNote = (noteData) => {
    setOrderNotes([...orderNotes, noteData]);
    setNotificationMessage('Nota agregada con éxito.');
    setShowNotification(true);
    setOrderNotesDrawerOpen(false);
  };

  const handleReportPayment = (paymentData) => {
    console.log('Payment reported:', paymentData);
    setNotificationMessage('Pago reportado con éxito.');
    setShowNotification(true);
    setReportPaymentModalOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex justify-end">
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* Modal Container */}
      <div
        id={`detalleCliente-${order.id}`}
        className="bg-white w-full max-w-[732px] h-full max-h-screen flex flex-col relative z-50"
      >
        {/* Header */}
        <div className="bg-[#020617] h-[64px] sticky top-0 flex items-center justify-between p-6">
          <h2 className="text-2xl text-white font-bold">Detalles del Pedido {order.number}</h2>
          <button className="text-white hover:text-gray-400" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Body */}
        <div className="flex-grow p-6 overflow-y-auto space-y-4">
          <AlertsOrders order={order} />
          <CustomerInfo order={order} getTravelDate={getTravelDate} />
          <RouteExtras order={order} />
          <TravelDetails
            order={order}
            getPickupDetails={getPickupDetails}
            getDropoffDetails={getDropoffDetails}
          />
          <PaymentAlert
            order={order}
            getPaymentMethod={getPaymentMethod}
            formatDate={formatDate}
            onReportPayment={openReportPaymentModal}
          />
        </div>

        {/* Footer */}
        <div className="p-4 bg-[#f1f5f9] flex justify-between items-center sticky bottom-0 z-10">
          <button
            className="bg-white text-black px-4 py-2 rounded-md hover:bg-gray-200 transition"
            onClick={onClose}
          >
            Cerrar
          </button>
          <div className="relative inline-block text-left" ref={dropdownRef}>
            <div>
              <button
                onClick={toggleDropdown}
                className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-white text-sm font-medium hover:bg-indigo-700 focus:outline-none"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded={isDropdownOpen}
              >
                Acciones
              </button>
            </div>

            {isDropdownOpen && (
              <div className="origin-bottom-right absolute right-0 bottom-[110%] mb-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <button
                    onClick={openOrderNotesDrawer}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    role="menuitem"
                  >
                    Notas del Pedido
                  </button>
                  <button
                    onClick={() => console.log('Cambiar Fecha')}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    role="menuitem"
                  >
                    Cambiar Fecha
                  </button>
                  <button
                    onClick={openUpdateModal}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    role="menuitem"
                  >
                    Cambiar Estado
                  </button>
                  <button
                    onClick={openReportPaymentModal}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    role="menuitem"
                  >
                    Reportar Pago
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <UpdateOrderModal
          isOpen={isUpdateModalOpen}
          onClose={closeUpdateModal}
          orderNumber={order.id}
          onUpdateStatus={handleUpdateStatus}
        />

        <OrderNotesDrawer
          isOpen={isOrderNotesDrawerOpen}
          onClose={closeOrderNotesDrawer}
          onSubmit={handleSaveNote}
          orderId={order.id}
        />

        <ReportPaymentModal
          isOpen={isReportPaymentModalOpen}
          onClose={closeReportPaymentModal}
          onSubmit={handleReportPayment}
        />

        {showNotification && (
          <Notification
            message={notificationMessage}
            onClose={() => setShowNotification(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DetallesOrden;