import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser
      ? { ...JSON.parse(storedUser), roles: JSON.parse(storedUser).roles || [] }
      : { roles: [] };
  });

  const [token, setToken] = useState(() => localStorage.getItem('token'));
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);
  const [assignedOrders, setAssignedOrders] = useState([]);

  const logout = () => {
    setUser({ roles: [] });
    setToken(null);
    setIsLoggedIn(false);
    setAssignedOrders([]);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('lastActivity');
  };

  const fetchAssignedOrders = useCallback(async (userId) => {
    try {
      const response = await axios.get(`https://transporzuma.com/admin/api/orders/assigned/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAssignedOrders(response.data);
    } catch (error) {
      console.error('Error al obtener los pedidos asignados:', error);
      setAssignedOrders([]);
    }
  }, [token]);

  useEffect(() => {
    if (user?.id && user.roles.includes('driver')) {
      fetchAssignedOrders(user.id);
    }
  }, [user, fetchAssignedOrders]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [user, token]);

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        isLoggedIn,
        assignedOrders,
        setUser,
        setToken,
        setIsLoggedIn,
        logout,
        fetchAssignedOrders,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
