import React from 'react';
import { AiOutlineClose } from 'react-icons/ai'; // Importa el icono de cierre de react-icons

const Notification = ({ message, onClose, type = 'success' }) => {
  const backgroundColor = type === 'success' ? 'bg-green-100' : 'bg-red-100';
  const textColor = type === 'success' ? 'text-green-800' : 'text-red-800';

  return (
    <div className={`${backgroundColor} p-4 rounded-lg shadow-lg fixed top-4 right-4 max-w-sm w-full flex items-start space-x-2`}>
      <div className="flex-shrink-0">
        <span className={`${textColor} text-lg`}>
          {type === 'success' ? '✅' : '⚠️'}
        </span>
      </div>
      <div className="flex-1 text-sm font-medium text-gray-700">
        {message}
      </div>
      <button onClick={onClose} className="flex-shrink-0 focus:outline-none">
        <AiOutlineClose className="w-5 h-5 text-gray-500 hover:text-gray-700" />
      </button>
    </div>
  );
};

export default Notification;
