import React, { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext'; // Importar el contexto de usuario

const MyProfile = () => {
  const { user } = useContext(UserContext); // Obtener los datos del usuario del contexto

  if (!user) {
    return <p>No estás logueado.</p>; // Mensaje si el usuario no está logueado
  }

  return (
    <div className="p-4 bg-white rounded shadow-md">
      <h2 className="text-xl font-semibold mb-4">Mi Perfil</h2>
      <p><strong>Nombre:</strong> {user.first_name}</p>
      <p><strong>Apellido:</strong> {user.last_name}</p>
      <p><strong>Email:</strong> {user.email}</p>
      {/* Puedes agregar más campos de usuario si es necesario */}
    </div>
  );
};

export default MyProfile;
