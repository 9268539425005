import React, { useState } from 'react';
import { IoManSharp, IoTicketOutline, IoCalendarOutline, IoTimeOutline, IoDocumentOutline, IoRefreshOutline, IoLocationOutline } from "react-icons/io5";
import { MdSurfing } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCloseCircleSharp } from "react-icons/io5";
import DetallesOrder from './DetallesOrder';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const OrdersTable = ({ orders, highlightedOrderIds = [] }) => {
  // State management
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination settings
  const ordersPerPage = 10;
  const totalPages = Math.ceil(orders.length / ordersPerPage);

  // Reorder orders to highlight specific IDs
  const reorderedOrders = [
    ...orders.filter(order => highlightedOrderIds.includes(order.id)),
    ...orders.filter(order => !highlightedOrderIds.includes(order.id)),
  ];

  const openDrawer = (order) => {
    setSelectedOrder(order);
  };

  const closeDrawer = () => {
    setSelectedOrder(null);
  };

  const capitalize = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const getFromData = (order) => {
    const product = order.line_items[0];
    const fromLocation = product.name;
    const hour = product.meta_data.find(meta => meta.key === 'Hora')?.value;

    return { fromLocation, hour };
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('es-CR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/Costa_Rica'
    });
  };

  const getTravelDate = (metaData) => {
    if (!Array.isArray(metaData)) {
      console.error('metaData no es un array:', metaData);
      return 'N/A';
    }
  
    const travelDateMeta = metaData.find(meta => meta.key === '_billing_departure_date');
    if (!travelDateMeta) return 'N/A';
  
    const [year, month, day] = travelDateMeta.value.split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  };

  const getDaysUntilTravel = (metaData) => {
    const travelDateMeta = metaData.find(meta => meta.key === '_billing_departure_date');
    if (!travelDateMeta) return 'N/A';

    const travelDate = new Date(travelDateMeta.value.split('T')[0]);
    const currentDate = new Date();
    const timeDiff = travelDate - currentDate;
    const daysUntilTravel = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysUntilTravel > 1) {
      return `Faltan ${daysUntilTravel} días para este viaje`;
    } else if (daysUntilTravel === 1) {
      return `Falta ${daysUntilTravel} día para este viaje`;
    } else if (daysUntilTravel === 0) {
      return 'Este viaje es Hoy, programa con el cliente';
    } else {
      return 'Este viaje ya ha pasado';
    }
  };

  const getDepartureTime = (metaData) => {
    const departureTimeMeta = metaData.find(meta => meta.key === '_billing_departure_time');
    return departureTimeMeta ? departureTimeMeta.value : 'N/A';
  };

  const getPickupDetails = (metaData) => {
    if (!Array.isArray(metaData)) return 'N/A';
    const pickupMeta = metaData.find(meta => meta.key === '_billing_pickup');
    return pickupMeta ? pickupMeta.value : 'N/A';
  };

  const getDropoffDetails = (metaData) => {
    if (!Array.isArray(metaData)) return 'N/A';
    const dropoffMeta = metaData.find(meta => meta.key === '_billing_dropoff');
    return dropoffMeta ? dropoffMeta.value : 'N/A';
  };

  const getReturnDate = (metaData) => {
    const returnDateMeta = metaData.find(meta => meta.key === '_billing_return_date');
    return returnDateMeta ? returnDateMeta.value : 'N/A';
  };
  
  const getReturnTime = (metaData) => {
    const returnTimeMeta = metaData.find(meta => meta.key === '_billing_return_time');
    return returnTimeMeta ? returnTimeMeta.value : 'N/A';
  };

  const getPaymentStatus = (order) => {
    const statusMap = {
      refunded: {
        color: '#f87171',
        icon: <IoCloseCircleSharp className="mr-1" />,
        text: 'Reembolsado',
      },
      cancelled: {
        color: '#94a3b8',
        icon: <IoCloseCircleSharp className="mr-1" />,
        text: 'Cancelado',
      },
      'on-hold': {
        color: '#3b82f6',
        icon: <IoCloseCircleSharp className="mr-1" />,
        text: 'En Rutero',
      },
      pending: {
        color: '#fbbf24',
        icon: <IoCloseCircleSharp className="mr-1" />,
        text: 'Pendiente de Pago',
      },
      completed: {
        color: '#2dd4bf',
        icon: <FaCircleCheck className="mr-1" />,
        text: 'Completado',
      },
      processing: {
        color: '#6366f1',
        icon: <FaCircleCheck className="mr-1" />,
        text: 'Nuevo Pedido',
      },
    };

    const status = statusMap[order.status] || { color: '#10b981', icon: null, text: '' };
    return (
      <p
        className="flex items-center text-sm font-semibold"
        style={{ color: status.color }}
      >
        {status.icon} {status.text}
      </p>
    );
  };

  const getItemCounts = (order) => {
    const totalPasajeros = order.line_items.filter(item => item.product_id !== 22666).reduce((acc, item) => acc + item.quantity, 0);
    const totalTablas = order.line_items.filter(item => item.product_id === 22666).reduce((acc, item) => acc + item.quantity, 0);
    return { totalPasajeros, totalTablas };
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = reorderedOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="space-y-4 p-4 overflow-x-hidden w-full max-w-full">
      {currentOrders.map((order, index) => {
        const { fromLocation, hour } = getFromData(order);
        const { totalPasajeros, totalTablas } = getItemCounts(order);
        const travelDate = getTravelDate(order.meta_data);
        const daysUntilTravel = getDaysUntilTravel(order.meta_data);
        const departureTime = getDepartureTime(order.meta_data);
        const creationDate = formatDateTime(order.date_created);
        const lastUpdatedDate = order.date_modified ? formatDateTime(order.date_modified) : 'N/A';
        const pickupDetails = getPickupDetails(order.meta_data);
        const dropoffDetails = getDropoffDetails(order.meta_data);
        const returnDate = getReturnDate(order.meta_data);
        const returnTime = getReturnTime(order.meta_data);
        

        const priceColor = {
          refunded: '#f87171',
          cancelled: '#94a3b8',
          'on-hold': '#3b82f6',
          pending: '#fbbf24',
          completed: '#2dd4bf',
          processing: '#6366f1',
          failed: '#94a3b8', // Only the price should be grey for failed orders
        }[order.status] || '#10b981';

        return (
          <div
            key={order.id}
            className={`relative shadow-md rounded-lg p-4 flex justify-between items-center w-full ${
              highlightedOrderIds.includes(order.id) ? 'bg-indigo-50' : 'bg-white'
            }`}
          >
            <div className="absolute left-[-30px] top-1/2 transform -translate-y-1/2 bg-gray-100 text-gray-500 rounded-full w-6 h-6 flex items-center justify-center font-semibold">
              {index + 1 + (currentPage - 1) * ordersPerPage}
            </div>

            <div className="flex-grow">
              <h2 className="font-semibold text-lg text-gray-700">
                <IoTicketOutline className="inline mr-2 text-indigo-700" /> 
                <span className="font-bold text-indigo-700">{order.number}</span>{' '}
                <span className="text-[#020617]">{capitalize(order.billing.first_name)}</span>{' '}
                <span className="text-[#1e293b]">{capitalize(order.billing.last_name)}</span>
              </h2>
              <p className="text-sm text-gray-500">Enlace: {fromLocation} - {hour}</p>
              <p className="text-sm text-[#8b5cf6] mb-2">
                <span className="text-xs font-medium mr-2">{daysUntilTravel}</span>
              </p>
              <p className="text-sm text-gray-500">
                <Tippy content={`Emitido: ${creationDate}`}>
                  <span 
                    title="Emitido"
                    className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                  >
                    <IoDocumentOutline className="inline mr-1" />
                  </span>
                </Tippy>
                <Tippy content={`Actualizado: ${lastUpdatedDate}`}>
                  <span 
                    title="Actualizado"
                    className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                  >
                    <IoRefreshOutline className="inline mr-1" />
                  </span>
                </Tippy>
                {totalPasajeros > 0 && (
                  <Tippy content={`Pasajeros: ${totalPasajeros}`}>
                    <span 
                      title="Pasajeros"
                      className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                    >
                      <IoManSharp className="inline mr-1" />
                    </span>
                  </Tippy>
                )}
                {totalTablas > 0 && (
                  <Tippy content={`Tablas: ${totalTablas}`}>
                    <span 
                      title="Tablas"
                      className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                    >
                      <MdSurfing className="inline mr-1" />
                    </span>
                  </Tippy>
                )}
                {travelDate !== 'N/A' && (
                  <Tippy content={`Fecha de Viaje: ${travelDate}`}>
                    <span 
                      title="Viajando"
                      className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                    >
                      <IoCalendarOutline className="inline mr-1" />
                    </span>
                  </Tippy>
                )}
                {departureTime !== 'N/A' && (
                  <Tippy content={`Hora de Salida: ${departureTime}`}>
                    <span 
                      title="Saliendo"
                      className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                    >
                      <IoTimeOutline className="inline mr-1" />
                    </span>
                  </Tippy>
                )}
                {pickupDetails !== 'N/A' && (
                  <Tippy content={`Pickup: ${pickupDetails}`}>
                    <span 
                      title="Pickup"
                      className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                    >
                      <IoLocationOutline className="inline mr-1" />
                    </span>
                  </Tippy>
                )}
                {dropoffDetails !== 'N/A' && (
                  <Tippy content={`Dropoff: ${dropoffDetails}`}>
                    <span 
                      title="Dropoff"
                      className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                    >
                      <IoLocationOutline className="inline mr-1" />
                    </span>
                  </Tippy>
                )}
                              {returnDate !== 'N/A' && (
                <Tippy content={`Fecha de Retorno: ${returnDate}`}>
                  <span 
                    title="Retornando"
                    className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                  >
                    <IoCalendarOutline className="inline mr-1 text-red-500" />
                  </span>
                </Tippy>
              )}
              {returnTime !== 'N/A' && (
                <Tippy content={`Hora de Retorno: ${returnTime}`}>
                  <span 
                    title="Retorno"
                    className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 hover:bg-indigo-100 hover:text-indigo-600 ml-2 cursor-pointer"
                  >
                    <IoTimeOutline className="inline mr-1 text-red-500" />
                  </span>
                </Tippy>
              )}
              </p>
            </div>  

            <div className="text-right w-[20%] flex flex-col items-end">
              <p className="text-xl font-bold" style={{ color: priceColor }}>
                ${order.total} <span className="text-sm font-normal" style={{ color: priceColor }}>USD</span>
              </p>
              {getPaymentStatus(order)}
              <button onClick={() => openDrawer(order)} className="text-[#64748b] hover:underline mt-2">
                Ver detalles
              </button>
            </div>
          </div>
        );
      })}

      {totalPages > 1 && (
        <div className="flex justify-center space-x-2 mt-4">
          {[...Array(totalPages)].map((_, pageIndex) => (
            <button
              key={pageIndex}
              onClick={() => paginate(pageIndex + 1)}
              className={`px-3 py-1 border rounded ${currentPage === pageIndex + 1 ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-600'}`}
            >
              {pageIndex + 1}
            </button>
          ))}
        </div>
      )}

      {selectedOrder && (
        <DetallesOrder
          order={selectedOrder}
          onClose={closeDrawer}
        />
      )}
    </div>
  );
};

export default OrdersTable;
