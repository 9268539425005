import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import OrdersTable from '../components/Pedidos/OrdersTable';
import { TbFilterSearch } from 'react-icons/tb';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { FiRefreshCw } from 'react-icons/fi';

const API = axios.create({ baseURL: 'https://app.transporzuma.com/admin/api/' });

const estadosPedidos = [
  { name: 'Nueva Reserva', value: 'processing' },
  { name: 'En Rutero', value: 'on-hold' },
  { name: 'Pagos Pendientes', value: 'pending' },
  { name: 'Completados', value: 'completed' },
  { name: 'Cancelados', value: 'cancelled' },
  { name: 'Reembolsados', value: 'refunded' },
  { name: 'Pago Fallido', value: 'failed' },
];

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState('processing');
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedOrderIds, setHighlightedOrderIds] = useState([]);
  const [dateType, setDateType] = useState(['travel']);
  const [selectedDate, setSelectedDate] = useState('');

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await API.get('/orders');
      
      // Verificar si la respuesta es un array válido antes de setear el estado
      const ordersData = Array.isArray(response.data) ? response.data : [];
      
      setOrders(ordersData);
      setFilteredOrders(ordersData);
    } catch (error) {
      console.error('Error al obtener pedidos:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const applyFilters = useCallback(() => {
    let filtered = [...orders];  // Hacemos una copia de orders

    // Filtrar por fecha seleccionada
    if (selectedDate) {
      filtered = filtered.filter(order => {
        const creationDate = new Date(order.date_created).toISOString().split('T')[0];
        const travelDateMeta = order.meta_data.find(meta => meta.key === '_billing_departure_date')?.value.split('T')[0];

        if (dateType.includes('creation') && dateType.includes('travel')) {
          return creationDate === selectedDate || travelDateMeta === selectedDate;
        } else if (dateType.includes('creation')) {
          return creationDate === selectedDate;
        } else if (dateType.includes('travel')) {
          return travelDateMeta === selectedDate;
        }
        return true;
      });
    }

    // Filtrar por búsqueda (searchTerm)
    if (searchTerm) {
      const matchingOrders = filtered.filter(order =>
        order.number.toString().includes(searchTerm) ||
        `${order.billing.first_name} ${order.billing.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.billing.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setHighlightedOrderIds(matchingOrders.map(order => order.id));
      filtered = matchingOrders;
    } else {
      setHighlightedOrderIds([]);
    }

    // Filtrar por estado seleccionado si está definido y no hay otros filtros
    if (estadoSeleccionado && !searchTerm && !selectedDate) {
      filtered = filtered.filter(order => order.status === estadoSeleccionado);
    }

    setFilteredOrders(filtered);
  }, [orders, selectedDate, dateType, searchTerm, estadoSeleccionado]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters, searchTerm, selectedDate, estadoSeleccionado]);

  const toggleDateType = (type) => {
    setDateType(prev => {
      if (prev.includes(type)) {
        return prev.filter(item => item !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const clearFilters = () => {
    setSearchTerm('');
    setSelectedDate('');
    setEstadoSeleccionado('processing');
    setDateType(['travel']);
    setFilteredOrders(orders);
    setHighlightedOrderIds([]);
  };

  return (
    <div className="flex h-full min-h-screen bg-gray-50 overflow-x-hidden">
      <div className="w-full max-w-[352px] bg-gray-100 p-6">
        <h2 className="text-lg font-bold mb-4">Estados de los Pedidos</h2>
        <ul className="space-y-2">
          {estadosPedidos.map((estado) => (
            <li key={estado.value}>
              <button
                className={`w-full text-left py-2 px-4 rounded-lg flex items-center justify-between ${
                  estadoSeleccionado === estado.value ? 'bg-indigo-600 text-white' : 'bg-white text-gray-700'
                } hover:bg-indigo-500 hover:text-white`}
                onClick={() => {
                  setEstadoSeleccionado(estado.value);
                  setSelectedDate('');
                  setSearchTerm('');
                  setFilteredOrders(orders.filter(order => order.status === estado.value));
                }}
              >
                <span>{estado.name}</span>
                <span
                  className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                >
                  {orders.filter(order => order.status === estado.value).length}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex-grow bg-white p-6 relative flex flex-col overflow-hidden">
        <div className="mb-4 flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold">Pedidos</h1>
            <p className="text-gray-600">
              Estos son los pedidos de tu plataforma. Utiliza los filtros para gestionar y visualizar los pedidos según el estado, fecha de creación y fecha de viaje, manteniendo a tus clientes informados de sus reservas y pagos.
            </p>
          </div>
          <button
            onClick={fetchOrders}
            className="bg-indigo-200 hover:bg-indigo-300 text-indigo-800 rounded-full p-2 transition"
          >
            <FiRefreshCw className="w-5 h-5" />
          </button>
        </div>

        <div className="flex flex-col md:flex-row items-center md:space-x-2 mb-4 space-y-2 md:space-y-0">
          <div className="flex items-center space-x-2">
            <button
              onClick={() => toggleDateType('travel')}
              className={`border border-gray-300 rounded-lg px-4 py-2 ${dateType.includes('travel') ? 'bg-indigo-200' : ''}`}
            >
              Fecha de Viaje
            </button>
            <button
              onClick={() => toggleDateType('creation')}
              className={`border border-gray-300 rounded-lg px-4 py-2 ${dateType.includes('creation') ? 'bg-indigo-200' : ''}`}
            >
              Fecha de Compra
            </button>
          </div>
          <input
            type="date"
            placeholder="Seleccionar fecha"
            className="border border-gray-300 rounded-lg p-2 w-full md:w-auto max-w-[180px]"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
          <div className="flex items-center border border-gray-300 rounded-lg p-2 space-x-1 w-full md:w-auto max-w-[250px]">
            <input
              type="text"
              placeholder="Buscar pedidos..."
              className="outline-none flex-grow h-full px-2 text-sm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <TbFilterSearch className="text-gray-500 cursor-pointer" />
            <ChevronRightIcon className="text-gray-500 cursor-pointer" onClick={clearFilters} />
          </div>
        </div>

        {loading ? (
          <div className="flex items-center justify-center h-64">
            <div className="w-16 h-16 border-4 border-indigo-200 border-t-indigo-500 rounded-full animate-spin"></div>
          </div>
        ) : (
          <div className="flex-grow overflow-y-auto max-w-full">
            {filteredOrders.length > 0 ? (
              <OrdersTable orders={filteredOrders} highlightedOrderIds={highlightedOrderIds} />
            ) : (
              <div className="text-center text-gray-500 py-10">
                Parece que no hay nada registrado con esos datos
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;
