import React, { useState, useRef } from 'react';
import { CreditCardIcon, ShieldCheckIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';

const PaymentAlert = ({ order, getPaymentMethod, formatDate, onReportPayment }) => {
  const paymentMethod = getPaymentMethod();

  const getTransactionId = () => {
    if (order.payment_method === 'tilopay') {
      const authMeta = order.meta_data.find(meta => meta.key === 'tilopay_auth_code');
      if (authMeta) {
        return authMeta.value;
      }
    }
    return order.transaction_id || 'Aún no registrado';
  };

  const transactionId = getTransactionId();
  const datePaid = order.date_paid ? formatDate(order.date_paid) : 'Aún no registrado';

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Métodos de Pago
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Detalles de pago del Pedido.
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
            <div className="sm:col-span-1">
              <div className="flex items-center">
                <CreditCardIcon className="w-6 h-6 mr-2 text-indigo-600" />
                <dt className="text-sm font-medium text-indigo-600">Método de Pago</dt>
              </div>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                <svg className="w-1.5 h-1.5 mr-2 text-black" fill="currentColor" viewBox="0 0 20 20">
                  <circle cx="10" cy="10" r="10" />
                </svg>
                {paymentMethod}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <div className="flex items-center">
                <ShieldCheckIcon className="w-6 h-6 mr-2 text-indigo-600" />
                <dt className="text-sm font-medium text-indigo-600">ID de Transacción</dt>
              </div>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                <svg className="w-1.5 h-1.5 mr-2 text-black" fill="currentColor" viewBox="0 0 20 20">
                  <circle cx="10" cy="10" r="10" />
                </svg>
                {transactionId}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <div className="flex items-center">
                <CalendarDaysIcon className="w-6 h-6 mr-2 text-indigo-600" />
                <dt className="text-sm font-medium text-indigo-600">Fecha de Pago</dt>
              </div>
              <dd className="mt-1 text-sm text-gray-900 flex items-center">
                <svg className="w-1.5 h-1.5 mr-2 text-black" fill="currentColor" viewBox="0 0 20 20">
                  <circle cx="10" cy="10" r="10" />
                </svg>
                {datePaid}
              </dd>
            </div>
          </div>
        </dl>
        <button
          className="bg-indigo-600 text-white px-4 py-2 mt-4 ml-4 rounded hover:bg-indigo-700"
          onClick={onReportPayment}
        >
          Reportar Pago
        </button>
      </div>
    </div>
  );
};

const ReportPaymentModal = ({ isOpen, onClose, onSubmit }) => {
  const [paymentNumber, setPaymentNumber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleSubmit = () => {
    onSubmit({ paymentNumber, paymentMethod });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-[400px]">
        <h2 className="text-lg font-bold mb-4">Reportar Pago</h2>
        <div className="mb-4">
          <label htmlFor="paymentNumber" className="block text-sm font-medium text-gray-700">
            Número de Pago
          </label>
          <input
            type="text"
            id="paymentNumber"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={paymentNumber}
            onChange={(e) => setPaymentNumber(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="paymentMethod" className="block text-sm font-medium text-gray-700">
            Método de Pago
          </label>
          <select
            id="paymentMethod"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="">Seleccione un método</option>
            <option value="tarjeta">Tarjeta de Crédito/Débito</option>
            <option value="efectivo">Efectivo</option>
            <option value="transferencia">Transferencia Bancaria</option>
          </select>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancelar
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportPaymentModal;
export { PaymentAlert };
